#Banner0_0.kjd3a69w8e-editor_css {
  background-image: url('../images/banner1.jpg');
}
#Banner0_0 .banner0-text-wrapper > .kjd3b9mz3ul-editor_css {
  font-size: 72px;
  color: rgb(251, 146, 36);
  width: 600px;
}
#Banner0_0 .banner0-text-wrapper > .kjd9l2n4vr-editor_css {
  font-size: 36px;
  color: rgb(251, 146, 36);
}
#Banner0_0 .banner0-text-wrapper > .kjd9ndw11or-editor_css {
  font-size: 18px;
  color: rgb(251, 146, 36);
  border-top-color: #fb9224;
  border-right-color: #fb9224;
  border-bottom-color: #fb9224;
  border-left-color: #fb9224;
}
#Content1_0 .ant-row > .ant-col > .kjd9u5p13an-editor_css {
  color: rgb(251, 146, 36);
}
#Content1_0 .ant-row > .ant-col > .kjd9w81aeng-editor_css {
  font-size: 18px;
}
#Feature2_0 .ant-row > .ant-col > .kjda5657ndn-editor_css {
  color: rgb(251, 146, 36);
}
#Feature2_0 .ant-row > .ant-col > .kjda6wbpmaf-editor_css {
  font-size: 18px;
}
#Content5_0 .home-page > .title-wrapper > .kjdb4kxtk0g-editor_css {
  color: rgb(251, 146, 36);
}
#Pricing0_0 .ant-row > .ant-col > .kjdbcgk584o-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  position: relative;
}
#Pricing0_0 .ant-row > .ant-col > .kjdblg7fhad-editor_css {
  font-size: 32px;
  color: rgb(251, 146, 36);
}
#Pricing0_0 .ant-col > div > .kjdbpltwhq7-editor_css {
  font-size: 16px;
  background-color: rgb(251, 146, 36);
  color: #fff;
}
#Pricing0_0 .ant-row > .ant-col > .kjdbqptqois-editor_css {
  height: 5rem;
  margin: 4px 0 0;
  padding: 4px 0px;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kjdch67f1ir-editor_css {
  font-weight: bold;
  color: rgb(251, 146, 36);
  width: 30rem;
}
#Pricing0_0 .ant-col > .kjdbqptqois-editor_css > .kjdbpltwhq7-editor_css {
  font-size: 32px;
  width: 10rem;
  height: 3rem;
  border-top-color: rgb(251, 146, 36);
  border-right-color: rgb(251, 146, 36);
  border-bottom-color: rgb(251, 146, 36);
  border-left-color: rgb(251, 146, 36);
  margin: 0.1px 0px 4px;
  padding: 6px 15px 4px;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kjdcjmaiwl-editor_css {
  padding: 0 0 5px;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .kjdch9yfwth-editor_css {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .kjdchyedebf-editor_css {
  background-image: url('../images/banner2.jpg');
  background-blend-mode: darken;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kjdclfdlyh9-editor_css {
  font-size: 32px;
  font-weight: normal;
  color: rgb(251, 146, 36);
  width: auto;
  height: auto;
  min-height: 3rem;
  background-color: rgba(0, 0, 0, 0);
  border-top-color: rgb(251, 146, 36);
  border-right-color: rgb(251, 146, 36);
  border-bottom-color: rgb(251, 146, 36);
  border-left-color: rgb(251, 146, 36);
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  margin: 0px 0px;
  padding: 5px 15px;
}
